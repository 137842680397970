/* eslint-disable no-nested-ternary */
/* eslint-disable spaced-comment */
import {
  useEffect, useState, useContext, useRef,
} from 'react';
import {
  Box,
  Container,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Markup } from 'interweave';
import _ from 'lodash';
import { useParams, navigate } from '../../utils/router';
import OvBreadCrumb from '../../components/common/headers/ovBreadCrumb';
import { UserContext } from '../../providers/userContextProvider';
import LoadingBox from '../../components/common/loaders/loadingBox';
import {
  AccountTypes,
  AffiliationRelations,
  affiliationRelations,
  affiliationTypes,
  AffiliationTypes,
  allAffiliationRelations,
  getAccountTypeName,
} from '../../components/account/resources';
import {
  DateOfBirth, Name, Sin, UpdatePhysicalAddressWizard,
} from '../../components/user';
import {
  CREATE_AFFILIATE, REMOVE_AFFILIATION, UPDATE_AFFILIATE, UPDATE_AFFILIATIONS, UPDATE_USER,
} from '../../components/account/graphql';
import { FETCH_AFFILIATES } from './graphql';
import { styles } from '../subAccountDetails/styles';
import AffiliatesListItem from '../../components/common/lists/affiliateListItem';
import ConfirmationModal from '../../components/common/modals/confirmationModal';
import { formatDate, generateAddressString } from '../../utils/commonMethods';
import { AffiliateUser } from '../../components/account/createAccountAuxiliaryWizard';
import FlowModal from '../../components/common/wrappers/modals/ovFlowModal';
import DropdownInput, { DropdownOption } from '../../components/common/inputs/dropdownInput';
import { genderList } from '../../components/user/resources';
import { PhysicalAddress } from '../../components/user/address/resources';
import AddAffiliateCardListItem from '../../components/common/lists/addAffiliateCardListItem';
import { colors } from '../../theme/colors';
import { orgSupportUrlVar } from '../../utils/localVariables';
import InProvinceSince from '../../components/user/inProvinceSince';

export interface Affiliation {
  id?: string,
  user?: {
    id: string,
    firstName?: string,
    lastName?: string,
    gender?: string,
    primaryEmail?: string,
    dateOfBirth?: Date,
    physicalAddress?: PhysicalAddress,
    inProvinceSince?: Date,
  },
  allocation?: number,
  type?: AffiliationTypes,
  relation?: string,
}

export const sortAffiliates = (list: string[]): string[] => {
  // eslint-disable-next-line
  const map: any = {
    ACCOUNT_HOLDER: 1,
    SUCCESSOR: 2,
    BENEFICIARIES: 3,
    TRUSTEE: 4,
    SETTLOR: 5,
    POWER_OF_ATTORNEY: 6,
    OTHER: 7,
  };
  return list.sort((x, y) => map[x] - map[y]);
};

interface AffiliatesItemProps {
  affiliateUser?: AffiliateUser
  onDone?: () => void;
}

export const getAffiliationTypeByAccount = (accountType: AccountTypes): AffiliationTypes[] => {
  const variant1 = [AccountTypes.RESP_ADULT, AccountTypes.PERSONAL];
  const variant2 = [AccountTypes.LIRA];
  const variant3 = [AccountTypes.RRSP_SPOUSAL, AccountTypes.RIF_SPOUSAL, AccountTypes.RRSP];
  const variant4 = [AccountTypes.TFSA, AccountTypes.RRIF, AccountTypes.PRIF, AccountTypes.LIF];
  const variant5 = [AccountTypes.RESP_SINGLE, AccountTypes.RESP_FAMILY];
  const variant6 = [
    AccountTypes.CORPORATE_CASH, AccountTypes.CORPORATE_CHARITY, AccountTypes.CORPORATE_ESTATE, AccountTypes.CORPORATE_TRUST,
  ];

  const excludedTypes = [AffiliationTypes.PRIMARY_BENEFICIARY, AffiliationTypes.CONTINGENT_BENEFICIARY, AffiliationTypes.JOINT];
  if (variant1.includes(accountType)) return [];
  if (variant2.includes(accountType)) return [AffiliationTypes.PRIMARY_BENEFICIARY, AffiliationTypes.OTHER];
  if (variant3.includes(accountType)) return [AffiliationTypes.PRIMARY_BENEFICIARY];
  if (variant4.includes(accountType)) return [AffiliationTypes.PRIMARY_BENEFICIARY, AffiliationTypes.SUCCESSOR];
  if (variant5.includes(accountType)) return [AffiliationTypes.PRIMARY_BENEFICIARY];
  if (variant6.includes(accountType)) return affiliationTypes.filter((x) => !excludedTypes.includes(x.key)).map((x) => x.key);
  if (accountType === AccountTypes.CASH_JOINT) return [AffiliationTypes.JOINT];

  return affiliationTypes.map((x) => x.key);
};

export const getAffiliationRelationByAccount = (accountType: AccountTypes, user: AffiliateUser): AffiliationRelations[] => {
  const listFilter = [AffiliationRelations.PRIMARY_CAREGIVER, AffiliationRelations.GUARDIAN];
  const familyRelations = [AffiliationRelations.CHILD, AffiliationRelations.GRANDCHILD, AffiliationRelations.SIBLING];
  const variant1 = [AccountTypes.RESP_ADULT, AccountTypes.PERSONAL, AccountTypes.RESP_SINGLE];
  const variant2 = [AccountTypes.TFSA, AccountTypes.RRIF, AccountTypes.PRIF, AccountTypes.LIF];

  if (variant1.includes(accountType)) return affiliationRelations.map((x) => x.key);
  if (accountType === AccountTypes.RESP_FAMILY) return familyRelations;
  if (variant2.includes(accountType)
    && user.affiliation?.type === AffiliationTypes.SUCCESSOR) return [AffiliationRelations.COMMON_LAW, AffiliationRelations.SPOUSE];
  return allAffiliationRelations.filter((x) => !listFilter.includes(x.key)).map((x) => x.key);
};

const allowedUpdateAffiliateFields = [
  'requestId', 'userId', 'firstName', 'middleName', 'lastName', 'primaryEmail', 'dateOfBirth', 'inProvinceSince', 'physicalAddress',
  'phone', 'gender', 'sin', 'citizenships', 'employmentStatus', 'maritalStatus', 'jobTitle', 'companyType',
  'isOfficerOfPublicCompany', 'isOwnerOfPublicCompany', 'isMemberOfIiroc', 'politicallyExposedForeignPerson',
  'politicallyExposedDomesticPerson', 'closeAssociateOfPEP', 'headOfInternationalOrganization',
];

const Affiliates = ({ ...props }: AffiliatesItemProps): JSX.Element => {
  const params = useParams();
  const matches = useMediaQuery('(max-width:960px)');
  const accountId = params.accountId ?? '';
  const accountType = params.accountType ?? '';
  const { t } = useTranslation(['base', 'user', 'account']);
  const [loading, setLoading] = useState(false);
  const [affiliationList, setAffiliationList] = useState(new Map<string, Affiliation[]>([]));
  const [user, setUser] = useState<AffiliateUser>(props.affiliateUser ?? {});
  const [openStepModal, setOpenStepModal] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [showRelationError, setShowRelationError] = useState(false);
  const [currentModalStep, setCurrentModalStep] = useState(0);
  const { userContext, setUserContext } = useContext(UserContext);
  const [showAffiliatesModal, setShowAffiliatesModal] = useState(false);
  const [updateAffiliationMutation] = useMutation(UPDATE_AFFILIATIONS);
  const [removeAffiliationMutation] = useMutation(REMOVE_AFFILIATION);
  const [createAffiliateMutation] = useMutation(CREATE_AFFILIATE);
  const [updateAffiliateMutation] = useMutation(user.affiliateOnly ? UPDATE_AFFILIATE : UPDATE_USER);
  const isMounted = useRef(false);

  const beneficiaryName: string = user.firstName ?? t('account:beneficiary.beneficiary');
  const modalTitle: string = isEditMode ? t('account:beneficiary.review.editAffiliate.title') : t('account:beneficiary.review.addAffiliate.title');
  const modalSubtitle: string = isEditMode ? t('account:beneficiary.review.editAffiliate.subtitle', { firstName: beneficiaryName }) : t('account:beneficiary.review.addAffiliate.subtitle');
  const modalActionBtnText: string = isEditMode ? t('account:beneficiary.review.saveChangesBtn') : t('account:beneficiary.review.addAffiliateBtn');

  const enableDelete: boolean = isEditMode && accountType !== AccountTypes.RESP_FAMILY;
  const singleOrFamily: (AccountTypes | undefined)[] = [AccountTypes.RESP_SINGLE, AccountTypes.RESP_FAMILY];
  const isSingleOrFamily: boolean = singleOrFamily.includes(accountType);
  const isTfsaAndRrsp: boolean = accountType === 'TFSA' || accountType === 'RRSP';

  const minAge: number | undefined = isSingleOrFamily ? 0 : undefined;
  const maxAge: number | undefined = isSingleOrFamily ? 21 : undefined;

  const accountRes = [AccountTypes.RESP_ADULT, AccountTypes.RESP_SINGLE, AccountTypes.PERSONAL, AccountTypes.CASH_JOINT];
  const showAddContributorButton = (): boolean => {
    if ((AccountTypes.RIF_SPOUSAL === accountType && affiliationList.size > 0)) return false;
    if ((AccountTypes.RIF_SPOUSAL === accountType && affiliationList.size === 0)) return true;
    return !accountRes.includes(accountType);
  };

  const checkFieldsValidity = async (): Promise<boolean> => {
    const firstNameValidity = await yup.string().required().isValid(user.firstName);
    const lastNameValidity = await yup.string().required().isValid(user.lastName);
    const addressValidity = await yup.string().required().isValid(user.physicalAddress?.streetName);
    const dobValidity = await yup.string().required().isValid(user.dateOfBirth);
    const sinValidity = await yup.string().required().isValid(user.sin);
    const genderValidity = await yup.string().required().isValid(user.gender);
    const typeValidity = await yup.string().required().isValid(user.affiliation?.type);
    return firstNameValidity && lastNameValidity && addressValidity && addressValidity && dobValidity
      && sinValidity && genderValidity && typeValidity;
  };

  const relationshipOptions: DropdownOption[] = allAffiliationRelations.filter((x) => getAffiliationRelationByAccount(accountType, user).includes(x.key)).map((x) => ({ imageUrl: '', text: x.title, value: x.key }));
  // const affiliationTypeOptions: DropdownOption[] = affiliationTypes.filter((x) => getAffiliationTypeByAccount(accountType).includes(x.key)).map((x) => ({ imageUrl: '', text: x.title, value: x.key }));
  const genderOptions: DropdownOption[] = genderList.map((x) => ({ imageUrl: '', text: x.name, value: x.value }));

  const modalSteps: JSX.Element[] = [
    <Name key="BeneficiaryName" user={user} updateUserState={setUser} onContinue={() => setOpenStepModal(false)} title={t('account:beneficiary.name')} subtitle="" />,
    <UpdatePhysicalAddressWizard key="physicalAddress" user={user} updateUserState={setUser} showStepper={false} shouldSaveUpdate={false} onContinue={() => setOpenStepModal(false)} title={t('account:beneficiary.address', { firstName: beneficiaryName })} subtitle={t('account:beneficiary.addressSubtitle')} />,
    <DateOfBirth key="DateOfBirth" user={user} shouldSaveUpdate={false} updateUserState={setUser} onContinue={() => setOpenStepModal(false)} title={t('account:beneficiary.dateOfBirth.title', { firstName: beneficiaryName })} subtitle={isTfsaAndRrsp ? '' : maxAge ? t('account:beneficiary.dateOfBirth.subtitle') : undefined} minAge={minAge} maxAge={maxAge} />,
    <Sin key="Sin" user={user} shouldSaveUpdate={false} updateUserState={setUser} onContinue={() => setOpenStepModal(false)} title={t('account:beneficiary.SIN', { firstName: beneficiaryName })} cardTitle={t('account:beneficiary.SINDisclaimer.title')} cardSubtitle1={t('account:beneficiary.SINDisclaimer.subtitle1')} cardSubtitle2={t('account:beneficiary.SINDisclaimer.subtitle2')} cardDescription={t('account:beneficiary.SINDisclaimer.subtitle3')} noSinText={t('account:beneficiary.noSin')} />,
    <InProvinceSince key="BeneficiaryInProvinceSince" onContinue={() => setOpenStepModal(false)} updateUserState={setUser} user={user} shouldSaveUpdate={false} title={t('account:beneficiary.inProvinceSince.title', { firstName: beneficiaryName })} subtitle={t('account:beneficiary.inProvinceSince.subtitle')} />,
  ];

  const dialogClose = (): void => {
    setUser({});
    setShowAffiliatesModal(false);
  };

  // eslint-disable-next-line
  const mapAffiliation = (values: any[]) => {
    const mappedAffiliation = values.reduce((result, current) => {
      const affiliationType = current.type ?? '';
      if (!result.has(affiliationType)) {
        result.set(affiliationType, []);
      }
      result.get(affiliationType)?.push(current);
      return result;
    }, new Map<string, Affiliation[]>());
    setAffiliationList(mappedAffiliation);
  };

  const setAffiliateData = (data: {
    fetchAccount: {
      account: {
        user: {
          id: string,
          firstName: string,
          lastName: string,
          gender: string,
          primaryEmail: string,
          physicalAddress: PhysicalAddress,
          affiliateOnly: boolean
        },
        affiliations: Affiliation[],
      }
    },
  }): void => {
    setLoading(false);
    if (!data.fetchAccount.account.affiliations) return;
    mapAffiliation(data.fetchAccount.account.affiliations);
    setLoading(false);
  };

  const [fetchAffiliates, { refetch }] = useLazyQuery(FETCH_AFFILIATES, {
    variables: {},
    onCompleted: setAffiliateData,
    nextFetchPolicy: 'standby',
    fetchPolicy: 'standby',
    onError: () => navigate(setUserContext, '/'),
  });

  useEffect(() => {
    if (!isMounted.current) {
      fetchAffiliates({
        variables: {
          accountId,
        },
      }).then();
      isMounted.current = true;
    }
  }, [fetchAffiliates, accountId]);

  useEffect(() => {
    if (
      [AccountTypes.RESP_FAMILY, AccountTypes.RESP_FAMILY_JOINT].includes(accountType)
      && user.affiliation?.type && user.affiliation.type === AffiliationTypes.PRIMARY_BENEFICIARY
    ) {
      const affiliations = affiliationList.get(AffiliationTypes.PRIMARY_BENEFICIARY);
      const affiliation = affiliations ? affiliations[0] : undefined;
      if (affiliation?.relation && user.affiliation?.relation && affiliation.relation !== user.affiliation.relation) {
        setDisabled(true);
        setShowRelationError(true);
      } else {
        setDisabled(false);
        setShowRelationError(false);
      }
    }
  }, [user.affiliation?.type, affiliationList, user.affiliation?.relation, accountType]);

  const addAffiliation = async (affiliate: AffiliateUser): Promise<void> => {
    const fetchAffiliatesListResponse = await fetchAffiliates({
      variables: {
        accountId,
      },
    });
    console.log((fetchAffiliatesListResponse.data?.fetchAccount?.account?.affiliations ?? []).map((item) => ({
      userId: item?.user?.id,
      type: item?.type,
      relation: item?.relation,
      signatureRequired: false,
      allocation: item?.allocation ?? undefined,
    })));
    updateAffiliationMutation({
      variables: {
        input: {
          accountId,
          affiliations: [
            ...(fetchAffiliatesListResponse.data?.fetchAccount?.account?.affiliations ?? []).map((item) => ({
              userId: item?.user?.id,
              type: item?.type,
              relation: item?.relation,
              signatureRequired: false,
              allocation: item?.allocation ?? undefined,
            })),
            ...[affiliate].map((item) => ({
              userId: item?.affiliation?.userId,
              type: item?.affiliation?.type,
              relation: item?.affiliation?.relation,
              signatureRequired: false,
              allocation: item.affiliation?.allocation ?? undefined,
            })),
          ],
        },
      },
      onCompleted: () => {
        refetch();
        dialogClose();
        if (props.onDone) props.onDone();
      },
      onError: (e) => {
        console.log('an error came ghere', e);
        setLoading(false);
      },
    }).then();
  };

  const createAffiliateAction = async (): Promise<void> => {
    const affiliate = user;
    const userId: string | undefined = affiliate?.affiliation?.userId;
    const dateOfBirth: string | undefined = (affiliate.dateOfBirth && affiliate.dateOfBirth !== undefined)
      ? formatDate(affiliate.dateOfBirth) : undefined;
    const inProvinceSince: string | undefined = user.inProvinceSince ? formatDate(user.inProvinceSince) : undefined;
    if (userId && isEditMode) {
      updateAffiliateMutation({
        variables: {
          input: _.pick(
            {
              ...{
                ...affiliate,
                dateOfBirth,
                inProvinceSince,
                userId: affiliate.affiliateOnly ? userId : undefined,
                affiliation: undefined,
                id: undefined,
              },
            },
            allowedUpdateAffiliateFields,
          ),
        },
        onCompleted: () => {
          refetch().then(() => setLoading(false));
          dialogClose();
          if (props.onDone) props.onDone();
        },
        onError: () => setLoading(false),
      }).then();
    } else {
      createAffiliateMutation({
        variables: {
          input: {
            organizationId: userContext.organization?.id,
            ...{
              ...affiliate, dateOfBirth, inProvinceSince, affiliation: undefined,
            },
          },
        },
        onCompleted: async (data) => {
          const usr: AffiliateUser = { ...affiliate, affiliation: { ...affiliate.affiliation, userId: data.createAffiliate.user.id } };
          await addAffiliation(usr);
        },
        onError: () => setLoading(false),
      }).then();
    }
  };

  const removeAffiliate = (): void => {
    removeAffiliationMutation({
      variables: {
        input: {
          accountId,
          affiliationId: user?.id,
        },
      },
      onCompleted: () => {
        refetch().then(() => setLoading(false));
        setOpenConfirmationModal(false);
        dialogClose();
      },
      onError: () => setLoading(false),
    });
  };

  const dialogConfirmClick = async (): Promise<void> => {
    if (!isEditMode) {
      const isValid = await checkFieldsValidity();
      if (!isValid) return;
    }
    createAffiliateAction().then();
  };

  const openModal = (step: number): void => {
    setCurrentModalStep(step);
    setOpenStepModal(true);
  };

  const editAffiliate = (affiliate: AffiliateUser): void => {
    setIsEditMode(true);
    setUser(affiliate);
    setShowAffiliatesModal(true);
  };

  const createAffiliate = async (type: AffiliationTypes): Promise<void> => {
    setUser({
      ...user,
      affiliation: {
        ...user.affiliation, type,
      },
    });
    setIsEditMode(false);
    setShowAffiliatesModal(true);
  };

  const getAffiliationTypePlaceHolders = (): AffiliationTypes[] => {
    const availableAffiliationTypes = Array.from(affiliationList.keys());
    let accountAffiliationTypes = [AffiliationTypes.PRIMARY_BENEFICIARY];
    if (accountType === AccountTypes.TFSA) accountAffiliationTypes = [AffiliationTypes.SUCCESSOR, AffiliationTypes.PRIMARY_BENEFICIARY];
    if (accountType === AccountTypes.CASH_JOINT) accountAffiliationTypes = [AffiliationTypes.JOINT];

    return accountAffiliationTypes.filter((x) => !availableAffiliationTypes.includes(x));
  };

  if (loading) {
    return <LoadingBox />;
  }

  const isAllocationRequired = [
    AccountTypes.TFSA, AccountTypes.RRSP,
  ].includes(accountType) && user.affiliation?.type === AffiliationTypes.PRIMARY_BENEFICIARY;

  return (
    <Container className="home-page" sx={styles.subAccountDetailsContainer}>
      <Box sx={styles.detailBox}>
        <Box sx={{ paddingLeft: '24px', position: 'relative', top: '-7px' }}>
          <OvBreadCrumb breadcrumbItems={[
            { title: t('base:breadcrumb.home'), onClick: () => navigate(setUserContext, '/') },
            { title: getAccountTypeName(accountType), onClick: () => navigate(setUserContext, '/account', { accountType, accountId }) },
            { title: t('account:breadCrumbValue.affiliates') },
          ]} />
        </Box>
      </Box>
      <Box
        sx={{
          border: `1px solid ${colors.portfolioCardBorder}`,
          borderRadius: '10px',
          backgroundColor: colors.baseComponentBackground,
          pt: 1,
        }}
      >
        <Typography sx={{ margin: '10px 23px' }} variant="heading2" style={styles.portfolioText}>{t('account:affiliates.screen.title', { accountType: getAccountTypeName(accountType) })}</Typography>
        {
          sortAffiliates(Array.from(affiliationList.keys())).map((type) => (
            <AffiliatesListItem
              t={t}
              key={type}
              type={type as AffiliationTypes}
              affiliationList={affiliationList}
              matches={matches}
              editAffiliate={editAffiliate}
              createAffiliate={() => { createAffiliate(type as AffiliationTypes); }}
              showAddButton={showAddContributorButton()}
            />
          ))
        }
        {
          getAffiliationTypePlaceHolders().map((type) => (
            <AddAffiliateCardListItem
              key={type}
              t={t}
              type={type as AffiliationTypes}
              createAffiliate={() => { createAffiliate(type as AffiliationTypes); }}
            />
          ))
        }
        <ConfirmationModal
          title={modalTitle}
          description={modalSubtitle}
          buttonText={modalActionBtnText}
          disabled={disabled}
          open={showAffiliatesModal}
          onButtonClick={dialogConfirmClick}
          cancelButton={enableDelete && getAffiliationTypeByAccount(accountType).length > 1 ? { onClick: () => setOpenConfirmationModal(true), text: t('account:beneficiary.review.deleteAffiliateBtn') } : undefined}
          onClose={dialogClose}>
          <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('account:beneficiary.review.fullName')}</Typography>
          <TextField
            data-testid="name-input"
            id="standard-basic"
            fullWidth
            disabled={isEditMode}
            variant="standard"
            sx={{ marginBottom: 2 }}
            value={`${user?.firstName ?? ''} ${user?.lastName ?? ''}`}
            inputProps={{
              readOnly: true,
              onClick: () => { openModal(0); },
            }}
          />
          <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('account:beneficiary.review.address')}</Typography>
          <TextField
            data-testid="address-input"
            id="standard-basic"
            fullWidth
            variant="standard"
            sx={{ marginBottom: 2 }}
            value={user?.physicalAddress ? generateAddressString(user.physicalAddress) : ''}
            inputProps={{ readOnly: true, onClick: () => { openModal(1); } }}
          />
          <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('account:beneficiary.review.dateOfBirth')}</Typography>
          <TextField
            data-testid="dob-input"
            id="standard-basic"
            fullWidth
            disabled={isEditMode}
            variant="standard"
            sx={{ marginBottom: 2 }}
            value={user?.dateOfBirth ? formatDate(user.dateOfBirth) : ''}
            inputProps={{ readOnly: true, onClick: () => { openModal(2); } }}
          />
          <Box>
            {
              user?.physicalAddress?.province === 'QC' && (
                <>
                  <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('account:beneficiary.review.inProvinceSince')}</Typography>
                  <TextField
                    data-testid="in-province-since-input"
                    id="standard-basic"
                    fullWidth
                    disabled={isEditMode}
                    variant="standard"
                    sx={{ marginBottom: 2 }}
                    value={user?.inProvinceSince ? formatDate(user.inProvinceSince) : ''}
                    inputProps={{ readOnly: true, onClick: () => { openModal(4); } }}
                  />
                </>
              )
            }
          </Box>
          <Box>
            {
              (isEditMode && user.affiliation?.relation
                && [AffiliationRelations.GUARDIAN, AffiliationRelations.PRIMARY_CAREGIVER].includes(user.affiliation.relation)) && (
                <Typography variant="paragraph3" style={{ marginTop: 10, textAlign: 'center' }}>
                  <Markup content={t(
                    `account:beneficiary.review.editAffiliate.contactSupportMessage.${user.affiliation?.relation}`,
                    { link: colors.link, linkUrl: orgSupportUrlVar() ?? 'https://onevest.zendesk.com/hc/en-us/requests/new' },
                  )}
                  />
                </Typography>
              )
            }
          </Box>
          <Box>
            {isAllocationRequired
              && (
                <>
                  <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('account:beneficiary.review.allocation')}</Typography>
                  <TextField
                    fullWidth
                    data-testid="relation-user-allocation"
                    value={user.affiliation?.allocation ?? ''}
                    variant="standard"
                    onChange={(e) => {
                      setUser({ ...user, affiliation: { ...user.affiliation, allocation: Number(e.target.value) } });
                    }}
                    error={(Number(user.affiliation?.allocation) > 100)}
                    id="allocation"
                    type="number"
                    InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment>, inputProps: { min: 0, max: 100 } }}
                  />
                </>
              )}
          </Box>
          {!isEditMode ? (
            <Box>
              <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('account:beneficiary.review.sin')}</Typography>
              <TextField
                data-testid="sin-input"
                id="standard-basic"
                fullWidth
                variant="standard"
                sx={{ marginBottom: 2 }}
                value={user?.sin ?? ''}
                inputProps={{ readOnly: true, onClick: () => { openModal(3); } }}
              />
              <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('account:beneficiary.review.gender')}</Typography>
              <DropdownInput
                dataTestId="gender-dropdown"
                onChange={(e) => {
                  setUser({ ...user, gender: e.target.value });
                }}
                options={genderOptions}
                value={user.gender ?? ''}
              />
              <Box style={{ marginBottom: 24 }} />
              {/* <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('account:beneficiary.review.affiliation')}</Typography> */}
              {/* <DropdownInput // temporarily disabling
                dataTestId="affiliation-dropdown"
                onChange={(e) => {
                  setUser({ ...user, affiliation: { ...user.affiliation, type: e.target.value as AffiliationTypes } });
                }}
                options={affiliationTypeOptions}
                value={user?.affiliation?.type ?? ''}
              /> */}
              <Box style={{ marginBottom: 24 }} />
              <Typography variant="captionSectionerMedium" style={{ marginBottom: 0 }}>{t('account:beneficiary.review.relationship')}</Typography>
              <DropdownInput
                dataTestId="relationship-dropdown"
                onChange={(e) => {
                  setUser({
                    ...user,
                    affiliation: {
                      ...user.affiliation, relation: e.target.value as AffiliationRelations,
                    },
                  });
                }}
                options={relationshipOptions}
                value={user?.affiliation?.relation ?? ''}
              />
              {showRelationError && <Typography color="error" variant="paragraph3" sx={{ mt: 1 }}>{t('account:affiliates.relationError')}</Typography>}
            </Box>
          ) : <Box />}
          <Box style={{ marginBottom: 48 }} />
        </ConfirmationModal>
        <ConfirmationModal
          title={t('account:affiliates.confirmationDialog.title')}
          description={t('account:affiliates.confirmationDialog.details')}
          buttonText={t('account:affiliates.confirmationDialog.continueButton')}
          open={openConfirmationModal}
          onButtonClick={removeAffiliate}
          cancelButton={{
            onClick: () => setOpenConfirmationModal(false),
            text: t('account:affiliates.confirmationDialog.cancelButton'),
          }}
          onClose={() => setOpenConfirmationModal(false)}
        />
        {openStepModal ? (
          <FlowModal
            open={openStepModal}
            goBack={() => setOpenStepModal(false)}
            onClose={() => setOpenStepModal(false)}
            showCloseButton
            component={(
              modalSteps[currentModalStep]
            )} />
        ) : null}
      </Box>
    </Container>
  );
};

Affiliates.defaultProps = {
  onDone: undefined,
  affiliateUser: undefined,
};

export default Affiliates;
